body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


/* @import '~antd/dist/antd.css'; */

.App {
  position: fixed;
  left: 50%;
  top: 2%;
  width:800px;
  height: 650px;
  margin-left: -400px;
  margin-right: -325px;
  font-family: 	Microsoft YaHei;
  border: 5px solid black;
  padding: .5rem 2rem;
  background: white;
  font-weight: 900;
  color: black;
  letter-spacing: 0.5px;
  line-height: 19px;
}


.envelope{
  width: 500px;
  height: 300px;
  border: 3px solid pink;
  position: fixed;
  left: 35%;
  transform: translate(-50%, 0);
  bottom: 30%;
  border-radius: 10px;
  box-shadow: 4px 4px 2px #888888;
  background-color: white;
  font-weight: 900;
}
.triangle-down{
  width: 0;
  height: 0;
  border-left: 252px solid transparent;
  border-right: 250px solid transparent;
  border-top: 160px solid pink;
  border-radius: 15px;
  position: absolute;
  left: -5px;
  top: -1px;
}
.triangle-up{
  width: 0;
  height: 0;
  border-left: 250px solid transparent;
  border-right: 254px solid transparent;
  border-bottom: 150px solid pink;
  border-radius: 15px;
  position: absolute;
  bottom: -1px;
  left: -4px;
}

@keyframes pound {
  to { transform: scale(1.4); }
}

.heart{
  width: 40px;
  height: 40px;
  z-index: 999;
  position: absolute;
  left: 225px;
  top: 130px;
  cursor: pointer;
  animation: pound .25s infinite alternate;
}




.text{
  position: absolute;
  top: -100px;
  left: 100px;
  color: blue;
  font-size: 24px;
}

.date{
  position: absolute;
  right: 25px;
  color: blue;
}
.date-text{
  font-size: 24px;
  color:deeppink;
}


.gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    width: 100%;
}


.gallery {
    display: flex;
    animation: scroll-left 360s linear infinite;
    transform: translateX(50%);
}

.gallery-item {
    flex: 0 0 auto;
    width: 400px; /* Consistent size for all images */
    margin: 0 20px;
    text-align: center;
}

.gallery-photo {
    width: 100%;
    height: 300px; /* Fixed height for consistent sizing */
    object-fit: cover; /* Ensures the image fills the set width and height */
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.gallery-subtitle {
    margin-top: 12px;
    font-size: 1.1rem;
    color: #333;
    font-weight: bold;
}

@keyframes scroll-left {
    from {
        transform: translateX(50%);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

